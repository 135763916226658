import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import BlogItem from './sidebar-blog-item';
import { SimilarItemsFactory } from './similar-items-factory';
import Utils from "../../utils";

const SimilarSchoolItems = ({ items }) => {
  return (
    <section className="similar-items">
      {items.map((item, i) => {
        let description = Utils.trimToN(item?.item?.body?.summary, 75, 3);
        return (
          <BlogItem
            key={i}
            title={item?.item?.title}
            description={description}
            date={item?.item?.created}
            path={item?.item?.path?.alias}
            image={item?.item?.relationships?.field_image?.image_style_uri?._294x192}
            className="similar-item"
            nodeType={item?.item?.internal?.type}
          />
        );
      })}
    </section>
  );
};

// (1.) Query for items
export default (props) => (
  <StaticQuery
    query={graphql`
      query SimilarSchoolItems {
        posts: allNodeLesson(
          limit: 1000
        ) {
          nodes {
            internal {
              type
            }
            langcode
            title
            created(formatString: "DD MMMM YYYY")
            body {
              summary
            }
            path {
              alias
            }
            field_access_control
            relationships {
              field_category: field_serie {
                name
                path {
                  alias
                }
              }
              field_tag {
                name
                path {
                  alias
                }
              }
              field_image {
                image_style_uri {
                  large
                  medium
                  thumbnail
                  wide
                  _294x192
                  _541x359
                  _653_432
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { category, tags, currentItemSlug, lang, currentAccessLevel } = props;

      const getPostsFromQuery = (posts) => {
        let items = [];
        items = posts?.nodes?.map((post) => {
          return post;
        });
        return items;
      };

      // (2.) Marshall the response into items
      const items = getPostsFromQuery(data.posts);

      // (3.) Use a SimilarItemsFactory to get my similar items
      const similarItems = new SimilarItemsFactory(
        items,
        currentItemSlug,
        lang
      )
        .setMaxItems(4)
        .setCategory(category)
        .setTags(tags)
        .setLanguage(lang)
        .getItems();

      // (4.) Filter based on the access level of the current page
      const filteredItems = similarItems.filter((item) => {
        if (currentAccessLevel === "Private") {
          // Show only private items if the page is private
          return item?.item?.field_access_control === "Private";
        } else {
          // Show all items that are not private or have no access control defined
          return (
            !item?.item?.field_access_control || 
            item?.item?.field_access_control !== "Private"
          );
        }
      });

      // (5.) Render it
      return <SimilarSchoolItems items={filteredItems} />;
    }}
  />
);
